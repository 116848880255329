import React, { useState, useEffect } from 'react';
import { initializeWeb3, web3 } from './web3'; // Import your initializeWeb3 function and web3 instance

const PaySeasonFee = ({ tokenId }) => {
  const [seasonFee, setSeasonFee] = useState('');
  const [isPaying, setIsPaying] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [account, setAccount] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Define isLoading state
  const [contractInstances, setContractInstances] = useState({}); // State to hold contract instances

  useEffect(() => {
    const initWeb3 = async () => {
      const instances = await initializeWeb3(setIsLoading); // Pass setIsLoading to initializeWeb3
      setContractInstances(instances); // Set contract instances
    };

    initWeb3();

    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        console.log('Please connect to MetaMask.');
      } else if (accounts[0] !== account) {
        setAccount(accounts[0]);
      }
    };

    // Set the initial account
    window.ethereum.request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        console.error('Error fetching accounts:', err);
      });

    // Subscribe to accounts change
    window.ethereum.on('accountsChanged', handleAccountsChanged);

    // Unsubscribe on cleanup
    return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
  }, [account]);

  const handlePaySeasonFee = async (e) => {
    e.preventDefault();

    if (!tokenId) {
      setErrorMessage('Token ID is required.');
      return;
    }

    setIsPaying(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      // Ensure contractInstance and account are available
      if (!contractInstances.realEstateInstance) {
        throw new Error('RealEstateTokenization contract instance is not available.');
      }

      if (!account) {
        throw new Error('Account is not available.');
      }

      if (!seasonFee) {
        throw new Error('Season fee has not been set.');
      }

      // Call paySeasonFee function from the contract
      await contractInstances.realEstateInstance.methods
        .paySeasonFee(tokenId)
        .send({ from: account, value: web3.utils.toWei(seasonFee, 'ether') });

      setSuccessMessage(`Fee paid successfully for token ID: ${tokenId}`);
    } catch (error) {
      console.error(`Error paying season fee: ${error.message}`);
      setErrorMessage(`Error paying season fee: ${error.message}`);
    } finally {
      setIsPaying(false);
      setTimeout(() => {
        setErrorMessage('');
        setSuccessMessage('');
      }, 5000);
    }
  };

  const fetchSeasonFee = async () => {
    if (contractInstances.realEstateInstance && account) {
      try {
        const fee = await contractInstances.realEstateInstance.methods.seasonFee().call();
        setSeasonFee(web3.utils.fromWei(fee, 'ether'));
      } catch (error) {
        console.error('Error fetching season fee:', error);
        setErrorMessage('Error fetching season fee.');
      }
    }
  };

  useEffect(() => {
    fetchSeasonFee();
  }, [contractInstances, account]);

  return (
    <div>
      <h2>Pay Season Fee</h2>
      {isLoading ? <p>Loading...</p> : (
        <form onSubmit={handlePaySeasonFee}>
          <div>
            <label>
              Token ID:
              <input
                type="text"
                value={tokenId}
                onChange={(e) => setErrorMessage('')} // Reset error if user changes input
                placeholder="Enter Token ID"
                required
              />
            </label>
          </div>
          <div>
            <p>Season Fee: {seasonFee || 'Fetching...'} ETH</p>
          </div>
          <div>
            <button type="submit" disabled={isPaying || !seasonFee}>
              {isPaying ? 'Processing...' : 'Pay Season Fee'}
            </button>
          </div>
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </form>
      )}
    </div>
  );
};

export default PaySeasonFee;
