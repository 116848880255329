import React, { useState, useEffect } from 'react';
import { initializeWeb3 } from './web3'; // Import your initializeWeb3 function

const BalanceOf = () => {
  const [addressToCheck, setAddressToCheck] = useState('');
  const [balance, setBalance] = useState('');
  const [isChecking, setIsChecking] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [account, setAccount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [contractInstances, setContractInstances] = useState({});

  useEffect(() => {
    const initWeb3 = async () => {
      const instances = await initializeWeb3(setIsLoading);
      setContractInstances(instances);
    };

    initWeb3();

    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        console.log('Please connect to MetaMask.');
      } else if (accounts[0] !== account) {
        setAccount(accounts[0]);
      }
    };

    window.ethereum.request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        console.error('Error fetching accounts:', err);
      });

    window.ethereum.on('accountsChanged', handleAccountsChanged);

    return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
  }, [account]);

  const handleCheckBalance = async (e) => {
    e.preventDefault();

    if (!addressToCheck) {
      setErrorMessage('Address is required.');
      return;
    }

    setIsChecking(true);
    setErrorMessage('');
    setSuccessMessage('');
    setBalance('');

    try {
      if (!contractInstances.realEstateInstance) {
        throw new Error('Contract instance is not available.');
      }

      if (!account) {
        throw new Error('Account is not available.');
      }

      const balanceResult = await contractInstances.realEstateInstance.methods.balanceOf(addressToCheck).call();

      setBalance(balanceResult);
      setSuccessMessage(`Balance checked successfully! Balance: ${balanceResult}`);
    } catch (error) {
      console.error(`Error checking balance: ${error.message}`);
      setErrorMessage(`Error checking balance: ${error.message}`);
    } finally {
      setIsChecking(false);
    }
  };

  return (
    <div>
      <h2>Check Balance</h2>
      {isLoading ? <p>Loading...</p> : (
        <form onSubmit={handleCheckBalance}>
          <div>
            <label>
              Address to Check:
              <input
                type="text"
                value={addressToCheck}
                onChange={(e) => setAddressToCheck(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <button type="submit" disabled={isChecking}>
              {isChecking ? 'Checking...' : 'Check Balance'}
            </button>
          </div>
          {balance !== '' && <p>Balance: {balance}</p>}
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </form>
      )}
    </div>
  );
};

export default BalanceOf;