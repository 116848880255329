import React from 'react';
import './AboutUsPage.css'; 
import founderImage from './IMG_0470.jpg'; 

const AboutUsPage = () => {
 

  return (
    <div className="about-us-page">
      <h1 className="about-us-title">About Us</h1>

 

      {/* Our Mission Section */}
      <section className="mission-section">
        <h2>Our Mission</h2>
        <p>At Ant Tokenizations, our mission is to transform the way people invest in and manage Real Estate** and Agriculture by leveraging cutting-edge technologies like blockchain, artificial intelligence, drone technology, and smart contracts. We are building a sustainable, secure, and inclusive ecosystem for real estate and agricultural investment, empowering fractional ownership and fostering transparency across all transactions.</p>
      </section>

      {/* Who We Are Section */}
      <section className="who-we-are-section">
        <h2>Who We Are</h2>
        <p>We are a team of passionate innovators, technologists, and industry experts committed to revolutionizing real estate and farmland ownership. Our diverse skill sets and experience allow us to tackle challenges from multiple perspectives, driving innovative solutions that bring lasting impact to both investors and farmers.</p>
      </section>

      {/* What We Do Section */}
      <section className="what-we-do-section">
        <h2>What We Do</h2>
        <p>We provide a comprehensive platform designed for Real Estate and Agricultural investment. Our technology-driven solutions enable secure, transparent, and efficient transactions, as well as optimized management of real estate and agricultural assets. Key offerings include:</p>
        <ul>
          <li><strong>Blockchain Technology:</strong> Ensuring secure, transparent, and immutable records for real estate and farmland ownership and transactions.</li>
          <li><strong>Artificial Intelligence:</strong> Delivering data-driven insights into land productivity, property valuation, and predictive analytics to help investors and farmers make smarter decisions.</li>
          <li><strong>Drone Technology:</strong> Providing precise, real-time data collection for superior farmland and property management, ensuring optimal resource use and yield maximization.</li>
          <li><strong>Smart Contracts:</strong> Automating transactions and governance processes, ensuring efficiency, security, and transparency throughout the investment cycle.</li>
        </ul>
      </section>

      {/* Our Vision Section */}
      <section className="vision-section">
        <h2>Our Vision</h2>
        <p>We envision a future where **real estate** and **farmland** investments are accessible to everyone. By tokenizing assets as NFTs, we enable fractional ownership, creating new opportunities for a broader range of investors. Our goal is to foster a sustainable, secure, and transparent ecosystem where both real estate and farmland investments thrive, ensuring long-term value for all stakeholders.</p>
      </section>

      {/* Our Values Section */}
      <section className="values-section">
        <h2>Our Values</h2>
        <ul>
          <li><strong>Innovation:</strong> We are committed to staying at the forefront of technological advancements, continuously innovating to create value and drive progress in real estate and farmland investment.</li>
          <li><strong>Transparency:</strong> We believe in open, transparent processes, ensuring that all transactions and data are secure, verifiable, and easy to understand for all stakeholders.</li>
          <li><strong>Inclusivity:</strong> We are focused on creating an inclusive ecosystem that empowers individuals and communities, providing opportunities for small and large investors alike.</li>
          <li><strong>Sustainability:</strong> We are dedicated to promoting sustainable practices in both real estate and agriculture, ensuring our solutions contribute to global environmental conservation and responsible resource use.</li>
        </ul>
      </section>

      {/* Meet Our Team Section */}
      <section className="team-section">
        <h2>Meet Our Team</h2>
        <p>Our team is composed of experienced professionals from diverse backgrounds, including experts in blockchain technology, artificial intelligence, drone technology, and real estate management. Together, we are committed to achieving our mission of transforming real estate and farmland investment through innovation, technology, and a dedication to sustainability.</p>

        {/* Founder Section */}
        <div className="founder-section">
          <h3>Our Founder</h3>
          <img src={founderImage} alt="Founder" className="founder-image" />
          <p>Moyosore Emmanuel Odumosu, our visionary founder, brings extensive expertise in blockchain, cloud computing, and decentralized applications. His passion for innovation and commitment to transforming real estate and farmland ownership drive our mission and vision.</p>
        </div>
      </section>
    </div>
  );
};

export default AboutUsPage;
